import React, { createRef } from 'react';
import Layout from '../components/layout/layout';
import Home from '../components/home/home';
import Navigation from '../components/navigation/navigation';
import About from '../components/about/about';
import Team from '../components/team/team';
import Prices from '../components/prices/prices';
import Footer from '../components/footer/footer';
import Parallax1 from '../components/parallax1';
import Parallax2 from '../components/parallax2';
import ContactForm from '../components/contactForm/contactForm';

const EnglishPage = () => {
  const {home, about, team, pricing, contact, footer} = createRef;
  const pricesArray = [
    {
      key: 1,
      name: 'Classical Massage (30min)',
      description: 'Classic massage, a versatile and relaxing massage that shapes the muscles.',
      price: '35€'
    },
    {
      key: 2,
      name: 'Classical Massage (45min)',
      description: 'Classic massage, a versatile and relaxing massage that shapes the muscles.',
      price: '40€'
    },
    {
      key: 3,
      name: 'Classical Massage (60min)',
      description: 'Classic massage, a versatile and relaxing massage that shapes the muscles.',
      price: '55€'
    },
    {
      key: 4,
      name: 'Student-discount -20% Massage 60 min',
      description: 'Classic massage, a versatile and relaxing massage that shapes the muscles.',
      price: '44€'
    },
    {
      key: 5,
      name: 'Student-discount -20% Massage 45 min',
      description: 'Classic massage, a versatile and relaxing massage that shapes the muscles.',
      price: '36€'
    },
    {
      key: 6,
      name: 'Student-discount -20% Massage 30 min',
      description: 'Classic massage, a versatile and relaxing massage that shapes the muscles.',
      price: '28€'
    }
  ]
  const aboutText = [
    {
      key: 1,
      content: `Welcome to Terapeuttiset Kädet Hieronta, terveyskeskus. We are a team of qualified massage therapists providing high quality massage therapy services, for a range of purposes. We offer Klassical massage, sports massage techniques including but not limited to Energy Muscle Release, Mobilization massage, Joint mobilization, Passive and Dynamic Stretching Massage. 
      Thermotherapy, Hot Stone Massage, Suction Cupping, Kinesthetic Taping and Basic Home exercise advice for maintaining muscle and joint mobility alongside massage treatments. We provide our treatments using adjustable massage tables in a lying down position. We also offer massages in a seated position using a specifically designed massage table and massages in this position enable treatments to be undertaken without the removal of clothing.
      `,
    },
    {
      key: 2,
      content: `Our aim as a team is to provide a range of the above services in a flexible and customer centered way to best meet the specific needs of each individual. Feel free to ask for specific treatments that you feel would benefit you, treatment prices would then be taloried to individual requirements.`,
    },
    {
      key: 3,
      content: `We accept payment in all forms, cash, cards, smartum, edenred, epassi.`,
    },
    {
      key: 4,
      content: `ID/Y -tunnus: 33138073`,
    },
    {
      key: 5,
      content: `We offer a number of discount options for students and retired individuals, and discounts on monthly subscriptions please ask for further information about these.`,
    },
    {
      key: 6,
      content: `Massage Therapy provides many benefits in terms of treating muscular pain, giving relaxation for stress and improving mental wellbeing. Massage techniques range from gentle rhythmic flowing movements that follow the natural lines of muscle structures, most usually described as classical massage, to more intense deeper stretching of muscles that go across muscle fibers rather than with them.`,
    },
  ]

  return (
      <Layout>
        <title>Home Page</title>
        <div className='site-wrapper'>
          <Navigation
            homeRoute={`/en`}
            coachingRoute={`#coaching`}
            aboutRoute={`#about`}
            teamRoute={`#team`}
            pricingRoute={`#pricing`}
            contactRoute={`#contact`}
            langLink={`FI`}
            contactLink={`CONTACT`}
            contactTitle={'Get in touch'}
            langTitle={'Finnish site'}
            navId={'homepage'}
            language={`/`}
            nav={`#contact`}
            home={`Home`}
            about={`About Us`}
            team={`Team`}
            pricing={`Prices`}
            contact={`Contact`}
          />
          <Home 
            ref={home}
            title={`Healing Hands Massage`} 
            bookingbtn={`Book Now`}
          />
          <main>
            <ContactForm
              formTitle={`Contact`}
              formCTA={`Leave a message`}
              OpeningTimeTitle={`Opening Hours`}
              Weekdays={`Mon-Friday: 09:00-22:00`}
              Weekends={`Saturday: 09:00-22:00`}
              ref={contact} />
            <About 
              sectionTitle={`About us`} 
              ref={about}
              aboutTextArray={aboutText}
            />
            <Parallax1/>
            <Team 
              sectionTitle={`Our Team`} 
              jamesRoute={`/james-en`}
              tainaRoute={`/taina-en`}
              ref={team}
            />
            <Prices title={`Prices`} ref={pricing} pricesArray={pricesArray} ctaText={`Click and reserve your session`} />
            <Parallax2/>
          </main>
          <Footer followMe={`Follow Me`} contactInfo={`Contact Info`} ref={footer}/>
        </div>
      </Layout>
  )
}

export default EnglishPage;